import Cards from 'react-credit-cards-2';
import { z } from '@/lib/es-zod.ts';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';
import { withMask } from 'use-mask-input';
import { addCreditCard } from '@/lib/payment-methods';
import { useMutation } from '@tanstack/react-query';
import SubmitButton from '@/components/ui/submit-button.tsx';
import { handleServerErrors } from '@/lib/forms.ts';
import { Alert, AlertDescription } from '@/components/ui/alert.tsx';
import { InfoIcon } from 'lucide-react';
import { useMember } from '@/lib/members/hooks/useMember.ts';
import LoadingBar from '@/components/common/loading-bar.tsx';
import { toast } from 'sonner';
import { ReactNode, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { GOOGLE_RECAPTCHA_SITE_KEY } from '@/lib/config.ts';

const formSchema = z.object({
  number: z.string().length(16),
  expiry: z
    .string()
    .length(5)
    .regex(/^(0[1-9]|1[0-2])\/\d{2}$/, 'La fecha de vencimiento debe tener el formato MM/AA'),
  cvc: z
    .string()
    .min(3)
    .max(4)
    .regex(/^\d{3,4}$/, 'El código de seguridad debe tener entre 3 y 4 dígitos numéricos'),
  name: z.string().min(2).max(255),
});

type PaymentMethodFormValues = z.infer<typeof formSchema>;

export type TPaymentMethodFormProps = {
  header?: ReactNode;
  submitButtonText?: string;
  onSubmitted?: () => Promise<void>;
};

export function PaymentMethodForm({ header, submitButtonText, onSubmitted = async () => {} }: TPaymentMethodFormProps) {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const { member, reload } = useMember();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (
      values: PaymentMethodFormValues & {
        recaptchaValue?: string | null;
      },
    ) => {
      await addCreditCard({
        ...values,
        number: parseInt(values.number),
        cvc: values.cvc,
      });
    },
    onSuccess: async () => {
      await reload();
      form.reset();
      toast.success('Tarjeta agregada correctamente');
      await onSubmitted();
    },
  });

  const form = useForm<PaymentMethodFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      number: '',
      expiry: '',
      cvc: '',
      name: '',
    },
    disabled: isPending,
  });

  const onSubmit = async (values: PaymentMethodFormValues) => {
    // Get recaptcha value
    recaptchaRef.current?.reset();
    const recaptchaValue = await recaptchaRef.current?.executeAsync();

    try {
      await mutateAsync({ ...values, recaptchaValue });
    } catch (error) {
      handleServerErrors<PaymentMethodFormValues>(form, error, {
        creditCard: 'number',
      });
    }
  };

  const state = form.watch();

  if (!member) {
    return <LoadingBar />;
  }

  return (
    <div className="w-full p-2 lg:p-6">
      <div className="m-auto flex flex-col space-y-6">
        {!!header && <div className="mb-6">{header}</div>}
        {!!member && !!member.creditCard && (
          <Alert>
            <InfoIcon className="h-6 w-6" />
            <AlertDescription>Tarjeta actual: {member.creditCard.digits}</AlertDescription>
          </Alert>
        )}
        <div className="mb-0 flex w-full items-center justify-center sm:mb-5 [&>*]:scale-75 [&>*]:sm:scale-100">
          <Cards
            number={state.number}
            expiry={state.expiry}
            cvc={state.cvc}
            name={state.name}
            // focused={state.focus}
          />
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 px-5 lg:px-16">
            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={GOOGLE_RECAPTCHA_SITE_KEY} hl="es" />
            <div className="flex flex-col items-center justify-center xl:flex-row xl:gap-5 [&>*]:w-full [&>*]:xl:w-1/2">
              <FormField
                control={form.control}
                name="number"
                render={({ field }) => (
                  <FormItem className="h-[100px]">
                    <FormLabel>Número</FormLabel>
                    <FormControl>
                      <Input placeholder="Número de tarjeta" {...field} maxLength={16} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="expiry"
                render={({ field }) => (
                  <FormItem className="h-[100px]">
                    <FormLabel>Vencimiento</FormLabel>
                    <FormControl>
                      <Input placeholder="MM/AA" {...field} ref={withMask('99/99')} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex flex-col items-center justify-center xl:flex-row xl:gap-5 [&>*]:w-full [&>*]:xl:w-1/2">
              <FormField
                control={form.control}
                name="cvc"
                render={({ field }) => (
                  <FormItem className="h-[100px]">
                    <FormLabel>Código de seguridad</FormLabel>
                    <FormControl>
                      <Input placeholder="Código de seguriad de tarjeta" {...field} maxLength={4} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="h-[100px]">
                    <FormLabel>Nombre</FormLabel>
                    <FormControl>
                      <Input placeholder="Nombre y Apellido" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex justify-end">
              <SubmitButton label={submitButtonText} isPending={isPending} />
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
