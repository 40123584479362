import axiosInstance from '@/lib/api/client.ts';

export const addCreditCard = async (creditCardData: {
  number: number;
  name: string;
  expiry: string;
  cvc: string;
}): Promise<void> => {
  await axiosInstance.post(`/payment-methods`, creditCardData);
};
